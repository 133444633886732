<template>
  <div>
    <img :src="imgBase64" style="margin: 0 auto;display: block">
    <canvas ref="canvas" class="canvas" style="opacity: 0"></canvas>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
  data() {
    return {
      imgBase64: ''
    }
  },
  mounted() {
    //生成初始化
    let canvas = this.$refs.canvas
    canvas.width = 500
    canvas.height = 350

    let query = this.$route.query

    let data = query.data ? query.data : 2024041800001

    let text = ''
    if (query.text) {
      text = data + '-' + query.text
    } else {
      text = data + '-设备昵称'
    }

    const options = {
      format: 'CODE128', // 条形码格式
      width: 2, // 条形码宽度
      fontSize: 16, // 条形码字体大小
      height: 60, // 条形码高度
      displayValue: true, // 是否显示条形码数据
      text: text,
      marginTop: 30,
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
    };


    JsBarcode(canvas, data, options);
    let imgBase64 = canvas.toDataURL("image/png")
    this.imgBase64 = imgBase64

  }
}
</script>

<style scoped lang="scss">
canvas {
  margin: 0 auto;
  display: block;
}
</style>